<template>
	<div class="bg-white px-6 py-24 sm:py-20 lg:px-8">
		<div class="mx-auto max-w-2xl text-center">
			<h2
				class="impro-header mt-2 text-3xl font-bold tracking-tight sm:text-5xl"
			>
				IMPRO Amsterdam 2025
			</h2>
			<p class='mt-2'><FestivalDates/></p>
			<p class="mt-6 text-lg leading-8 text-gray-800">
				Applications are now open!
			</p>
		</div>
	</div>
</template>
<script setup lang='ts'>
</script>
